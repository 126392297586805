<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">预约看板</div>

			<div class="mt20">
				<div class=" pd30 bg-w" style="min-height: 800px;">
					<div class="flex alcenter">


						<div class="ml30 flex wrap">
							<div class="flex alcenter mr30" v-for="(item,index) in settings">
								<div class="setting-item" :style="{background:item.mainColor}"></div>
								<div class="ml8 ft14 ftw600 cl-info">{{item.status_means}}</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="kanban-table-header">
							<div class="flex alcenter">
								<img src="../../../assets/image/icon_title01@2x.png" class="kanban-table-header-left"/>
								<div style="width: calc(100% - 170px);" class="kanban-table-header-right">
									<div class="flex alcenter space" style="height: 100%;">
										<div class="last-week-button" @click="getUpWeek()">
											<div class="flex alcenter center">
												<i class="iconfont iconlast ft12 cl-info"/>
												<div class="ml2">上一周</div>
											</div>
										</div>

										<div style="width: 100%;" class="flex alcenter">
											<template v-for="(item,index) in getDays">
												<div class="this-week-item flex alcenter center on" v-if="index == selectIndex">
													<div>{{item.today == 1? '今天' : '周'+item.wd}}</div>
													<div class="ml4">{{item.md}}</div>
												</div>
												<div v-else class="this-week-item flex alcenter center" @click="changeDay(index)">
													<div class="cl-main" >周{{item.wd}}</div>
													<div class="ml4">{{item.md}}</div>
												</div>
											</template>
										</div>

										<div class="next-week-button" @click="getDownWeek()">
											<div class="flex alcenter center">
												<div >下一周</div>
												<i class="iconfont iconnext ft12 cl-theme ml2"/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="" v-if="datas.length>0">
							<div class="kanban-table-content-member-item" v-for="(item,index1) in datas">
								<div class="flex alcenter" >
									<div class="kanban-table-content-member-item-left flex alcenter" v-if="item.serviceman">
										<img :src="item.serviceman.face" />
										<div class="ml10">
											<div class="ft14 ftw500 cl-black">{{item.serviceman.name}}</div>
											<div class="ft12 ftw400 cl-info mt5">{{item.serviceman.mobile}}</div>
										</div>
									</div>

									<div class="kanban-table-content-member-item-left flex alcenter" v-else>
										待分配服务人员
									</div>
									<div class="kanban-table-content-member-item-right">
										<div class="flex wrap">
											<div :style="{border: '1px solid '+setting[order.status].mainColor}" class="kanban-table-content-member-appoint-item flex alcenter" v-for="(order,index2) in item.orders">
												<div :style="{background: setting[order.status].mainColor}" class="kanban-table-content-member-appoint-item-border"></div>
												<div class="flex alcenter space ml10 pr15" style="width: 100%;">
													<div>
														<div class="ft14 ftw500 " :style="{color:setting[order.status].memberColor}">{{order.member_name}} {{order.member_mobile}}</div>
														<div class="flex alcenter mt8">
															<div :style="{background: setting[order.status].mainColor}" class="kanban-table-content-member-appoint-item-time">{{order.start_time}}-{{order.end_time}}</div>
															<div :style="{color:setting[order.status].projectColor}" class="ml8 ft14 ftw400  text-over4">{{order.project_name}}</div>
														</div>
													</div>
													<div>
														<a-dropdown placement="bottomRight">
															<span class="more-act">
																<i class="iconfont iconmore_gray"></i>
															</span>
															<a-menu slot="overlay">
																<a-menu-item>
																	<a class="menu-act" href="javascript:;" @click="showDetailAct(index1,index2)">
																		<i class="iconfont ft14 iconsee"></i>
																		<span class="ml10">查看详情</span>
																	</a>
																</a-menu-item>
																<a-menu-item v-if="order.status==1">
																	<a class="menu-act" href="javascript:;" @click="sureAppointAct(index1,index2)">
																		<i class="iconfont ft14 iconorder_operation_confirm"></i>
																		<span class="ml10">确认预约</span>
																	</a>
																</a-menu-item>
																<a-menu-item v-if="order.status==3">
																	<a class="menu-act" href="javascript:;" @click="startServiceAct(index1,index2)">
																		<i class="iconfont ft14 iconorder_operation_startservice"></i>
																		<span class="ml10">开始服务</span>
																	</a>
																</a-menu-item>
																<a-menu-item v-if=" order.status==4">
																	<a class="menu-act" href="javascript:;"  @click="completeAppointAct(index1,index2)">
																		<i class="iconfont ft14 iconorder_operation_cancle"></i>
																		<span class="ml10">完成服务</span>
																	</a>
																</a-menu-item>
																<a-menu-item v-if="order.status==1 || order.status==3">
																	<a class="menu-act" href="javascript:;"  @click="cancelAppointAct(index1,index2)">
																		<i class="iconfont ft14 iconorder_operation_cancle"></i>
																		<span class="ml10">取消预约</span>
																	</a>
																</a-menu-item>
															</a-menu>
														</a-dropdown>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-else class="mt20 flex center">
							<a-empty/>
						</div>
						<div class="flex center mt20">
							<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
						</div>

						<div v-if="showDetailVisible">
							<order-detail :visible="showDetailVisible" :order="order" @cancel="cancelShowDetail" @sureAppoint="sureAppoint"
							@cancelAppoint="cancelAppoint" @sureWriteOff="sureWriteOff"></order-detail>
						</div>
						<div v-if="cancelAppointVisible">
							<cancel-appoint :appointment_id="appointment_id" :visible="cancelAppointVisible" @cancel="cancelCancelAppoint" @ok="okCancelAppoint"></cancel-appoint>
						</div>
						<div v-if="addAppointmentVisible">
							<add-appointment :visible="addAppointmentVisible" @cancel="cancelAddAppointment" @ok="okAddAppointment"></add-appointment>
						</div>
						<div v-if="distributionServicemanVisible">
							<distribution-serviceman :appointment_id="appointment_id" :visible="distributionServicemanVisible" @cancel="cancelDistributionServiceman"
							@ok="okDistributionServiceman"></distribution-serviceman>
						</div>
						<div v-if="completeAppointVisible">
							<complete-appoint :order="order" :visible="completeAppointVisible" @cancel="cancelCompleteAppoint" @ok="okCompleteAppoint"></complete-appoint>
						</div>
					</div>
				</div>
			</div>
		</a-spin>

	</div>
</template>

<script>
	import helper from '../../../common/helper.js';
	import cancelAppoint from './components/order/modal/detail/modal/cancelAppoint.vue';
	import addAppointment from './components/order/modal/addAppointment.vue';
	import completeAppoint from './components/order/modal/completeAppoint.vue';
	import distributionServiceman from './components/order/modal/distributionServiceman.vue'
	export default {
		components:{

			cancelAppoint,
			addAppointment,
			distributionServiceman,
			completeAppoint,
		},
		data() {
			return {
				loading: false,
				showDetailVisible:false,
				cancelAppointVisible:false,
				addAppointmentVisible:false,
				distributionServicemanVisible:false,
				completeAppointVisible:false,
				appointment_id:0,
				order:{},
				settings:[
					{status:1,status_means:'待确认',mainColor:'#4772FF',memberColor:'#000000',projectColor:'#656A75'},
					{status:3,status_means:'待服务',mainColor:'#FF7519',memberColor:'#000000',projectColor:'#656A75'},
					{status:4,status_means:'服务中',mainColor:'#CE5FFB',memberColor:'#000000',projectColor:'#656A75'},
					{status:8,status_means:'已完成',mainColor:'#A6AEC2',memberColor:'#A6AEC2',projectColor:'#A6AEC2'},
					{status:-1,status_means:'已取消',mainColor:'#EBEDF5',memberColor:'#A6AEC2',projectColor:'#A6AEC2'}
				],
				setting:[],
				week:0,
				selectIndex:0,
				today:null,
				days:[],
				pagination: {
					current: 1,
					pageSize: 5, //每页中显示10条数据
					total: 0,
				},
				datas: [],
			}
		},
		created() {
			this.setting[1]={status:1,status_means:'待确认',mainColor:'#4772FF',memberColor:'#000000',projectColor:'#656A75'};
			this.setting[3]={status:3,status_means:'待服务',mainColor:'#FF7519',memberColor:'#000000',projectColor:'#656A75'};
			this.setting[4]={status:4,status_means:'服务中',mainColor:'#CE5FFB',memberColor:'#000000',projectColor:'#656A75'};
			this.setting[8]={status:8,status_means:'已完成',mainColor:'#A6AEC2',memberColor:'#A6AEC2',projectColor:'#A6AEC2'};
			this.setting[-1]={status:-1,status_means:'已取消',mainColor:'#EBEDF5',memberColor:'#A6AEC2',projectColor:'#A6AEC2'};

			let days = helper.getWeekArr();
			this.days = days;
			this.today = helper.getTimeArr();//获取今天
			this.selectIndex = this.today.w;

			this.getLists();
		},

		computed:{
			getDays(){
				if(this.today == null) return [];
				let arr = new Array;
				for(var a  in this.days){
					arr.push({
						today:this.days[a].today,
						md:this.days[a].md,
						wd:this.days[a].wd,
						day:this.days[a].y + '-' +this.days[a].m + '-'+this.days[a].d,
					});
				}
				return arr;
			}
		},
		methods: {

			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/showAppointmentKanban',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					day:this.getDays[this.selectIndex].day,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			sureAppointAct(index1,index2){
				this.$confirm({
					title:'确认这个预约吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/sureAppointment',{
								appointment_id:this.datas[index1].orders[index2].appointment_id,
							}).then(res=>{
								this.$message.success('确认成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			sureAppointAct(index1,index2){
				if(this.datas[index1].orders[index2].serviceman_id!=0){
					this.$confirm({
						title:'确认这个预约吗？',
						okText:"确定",
						okType:"danger",
						cancelText:"取消",
						onOk : ()=>{
							return new Promise((resolve,reject)=>{
								this.$http.api('platform/sureAppointment',{
									appointment_id:this.datas[index1].orders[index2].appointment_id,
								}).then(res=>{
									this.$message.success('确认成功');
									this.getLists();
								}).catch(res=>{
									console.log(res);
								}).finally(()=>{
									resolve();
								})
							})
						}
					})
				}else{
					this.distributionServicemanAct(index1,index2);
				}
			},

			distributionServicemanAct(index1,index2){
				this.appointment_id=this.datas[index1].orders[index2].appointment_id;
				this.distributionServicemanVisible=true;
			},
			cancelDistributionServiceman(){
				this.distributionServicemanVisible=false;
			},
			okDistributionServiceman(){
				this.distributionServicemanVisible=false;
				this.getLists();
			},

			startServiceAct(index1,index2){
				this.$confirm({
					title:'确认开始服务了吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/startService',{
								appointment_id:this.datas[index1].orders[index2].appointment_id,
							}).then(res=>{
								this.$message.success('操作成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			//完成服务
			completeAppointAct(index1,index2){
				this.order=this.datas[index1].orders[index2],
				this.completeAppointVisible=true;
			},
			cancelCompleteAppoint(){
				this.completeAppointVisible=false;
			},
			okCompleteAppoint(){
				this.completeAppointVisible=false;
				this.getLists();
			},

			//查看订单详情
			showDetailAct(index1,index2){
				this.$router.push('/appointmentAdmin/orderDetail?id='+this.datas[index1].orders[index2].appointment_id)
			},

			//取消预约
			cancelAppointAct(index1,index2){
				this.appointment_id=this.datas[index1].orders[index2].appointment_id,
				this.cancelAppointVisible=true;
			},
			cancelCancelAppoint(){
				this.cancelAppointVisible=false;
			},
			okCancelAppoint(){
				this.cancelAppointVisible=false;
				this.getLists();
			},

			//新增预约
			addAppointmentAct(){
				this.addAppointmentVisible=true;
			},
			cancelAddAppointment(){
				this.addAppointmentVisible=false;
			},
			okAddAppointment(){
				this.addAppointmentVisible=false;
				this.getLists();
			},

			getUpWeek(){
				this.week = this.week - 1;
				let days = helper.getWeekArr(this.week);
				this.days = days;
				this.getDatas();
			},
			getDownWeek(){
				this.week = this.week + 1;
				let days = helper.getWeekArr(this.week);
				this.days = days;
				this.selectIndex = 0;
				this.getDatas();
			},

			changeDay(index){
				this.selectIndex=index;
				this.getDatas();
			},

			getDatas(){
				this.loading=false;
				this.pagination.current=1;
				this.getLists();
			},

			pageChange(e){
			    this.pagination.current = parseInt(e);
			    this.getLists();
			},
		}
	}
</script>

<style>
	.setting-item{
		width: 14px;
		height: 14px;
	}

	.kanban-table-header{
		width: 100%;
		height: 60px;
		background: #FFFFFF;
	}

	.kanban-table-header-left{
		width: 170px;
		height: 60px;
	}
	.kanban-table-header-right{
		width: calc(100% - 170px);
		height: 60px;
		border: 1px solid #EBEDF5;
		border-left: none;
		padding: 0px 20px;
		line-height: 1;
	}

	.last-week-button{
		width: 90px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		font-size: 14px;
		font-weight: 500;
		color: #656A75;
		text-align: center;
		line-height: 38px;
		cursor: pointer;
	}

	.this-week-item{
		height: 40px;
		font-size: 12px;
		font-weight: 500;
		color: #A6AEC2;
		width: 10%;
		margin-left: 3%;
		margin-left: 3%;
		cursor: pointer;
	}

	.this-week-item.on{
		height: 40px;
		background: #4772FF;
		border-radius: 4px;
		color: #FFFFFF;
	}

	.next-week-button{
		width: 90px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #4772FF;
		font-size: 14px;
		font-weight: 500;
		color: #4772FF;
		text-align: center;
		line-height: 38px;
		cursor: pointer;
	}

	.kanban-table-content-member-item{
		width: 100%;
		min-height: 104px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		border-top: none;
	}

	.kanban-table-content-member-item-left{
		width: 168px;
		height: 100%;
		background: #FFFFFF;
		padding: 20px;
		line-height: 1;
	}
	.kanban-table-content-member-item-left img{
		width: 40px;
		height: 40px;
		border-radius: 2px;
	}

	.kanban-table-content-member-item-right{
		width: calc(100% - 168px);
		min-height: 104px;
		border-left: 1px solid #EBEDF5;
		padding: 15px 0px;
	}

	.kanban-table-content-member-appoint-item{
		width: 320px;
		height: 74px;
		background: #FFFFFF;
		border-radius: 4px;
		margin-left: 20px;
		margin-bottom: 10px;
	}

	.kanban-table-content-member-appoint-item-border{
		width: 5px;
		height: 74px;
		border-radius: 4px 0px 0px 4px;
	}

	.kanban-table-content-member-appoint-item-time{
		min-width: 86px;
		border-radius: 2px;
		padding: 0px 4px;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
	}
</style>
